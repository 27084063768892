<template>
  <Page>
    <template v-slot:help>
      <NotionHelp topic="channels" />
    </template>
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="openCreationModal"
        icon="mdi-plus-circle"
        text="Créer"
      />
    </template>
    <template v-slot:content>
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page.sync="table.options.page"
          :server-items-length="result.count"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item.default="{ item }">
            {{ item.default ? 'oui' : 'non' }}
          </template>
        </v-data-table>
      </v-sheet>
      <ChannelCreationModal
        :opened.sync="modal.opened"
        @success="load"
      />
    </template>
  </Page>
</template>

<script>
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ChannelCreationModal from '@/views/Channels/Modal/CreationModal.vue'
import { getQueryString } from '@/helpers/queryString'
import NotionHelp from '@/components/core/NotionHelp.vue'
import Page from '@/components/core/Page.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ChannelsList',
  components: {
    ChannelCreationModal,
    ButtonAction,
    NotionHelp,
    Page,
  },
  mixins: [snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      request: null,
      modal: {
        opened: false,
      },
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            text: 'Id',
            value: 'id',
            sortable: false,
          },
          {
            text: 'Nom',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Défault',
            value: 'default',
            sortable: false,
          },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'catalog/channels/?' + getQueryString(
        this.table.options.page,
        {},
        this.table.options.sortBy,
        this.table.options.sortDesc,
        this.table.options.itemsPerPage
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    cancel () {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    async load () {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      try {
        const response = await this.$axios.get(this.queryString, {
          cancelToken: axiosSource.token,
        })
        this.result.items = response.data['hydra:member']
        this.result.count = response.data['hydra:totalItems']
        this.result.loading = false
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      } finally {
        this.result.loading = false
        this.request = null
      }
    },
    openCreationModal() {
      this.modal.opened = true
    },
  },
}
</script>
