<template>
  <v-dialog
    :value="openedValue"
    max-width="500px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>
        <v-icon
          left
        >
          mdi-connection
        </v-icon>
        Création d'un nouveau canal
      </v-card-title>
      <v-form
        aria-autocomplete="none"
        @submit.prevent="add()"
      >
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.name"
                outlined
                hide-details
                dense
                label="Nom"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="add()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'ChannelCreationModal',
  mixins: [snackbarMixin],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading:false,
      form: {
        name: null,
      },
    }
  },
  
  computed: {
    openedValue: {
      get() {
        return this.opened
      },
      set(value) {
        this.$emit('update:opened', value)
      },
    },
  },
  methods: {
    close() {
      this.openedValue = false
      this.loading = false
      this.form = {
        name: null,
      }
    },
    async add() {
      this.loading = true

      try {
        await this.$axios.post('/catalog/channels', this.form)
        this.snackbarSuccess('success')

        this.close()
        this.$emit('success')

      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('global.message.error', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
