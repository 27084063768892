/* eslint-disable */
export default {
  'sync-products-csv-default': 'https://www.notion.so/Sourcing-de-Produits-via-notre-Matrice-CSV-18cdfa80a29680039cf8ca6217d2048c',
  'sync-products-shopify': 'https://www.notion.so/Sourcing-de-Produits-plus-via-Shopify-1a6dfa80a29680a6ab6eead8e92b5400',
  'shipments': 'https://www.notion.so/Lighthouse-Colis-45dd732635014912bf6732877bc92220',
  'shipments-import-tracking': 'https://www.notion.so/Import-Tracking-Externe-8043db022b33423db4fec5930fbd4bf2?pvs=4#9afe820321344788867cd5656f2fe352',
  'shipments-export-untracked-external-shipments': 'https://www.notion.so/Lighthouse-Colis-45dd732635014912bf6732877bc92220?pvs=4#852c4025bb1744c393aa534dec4e2a77',
  'inventories': 'https://www.notion.so/Gestion-des-inventaires-19fdfa80a296803fa9fac0d973ee7e2d?pvs=4#19fdfa80a29680c281a7ea014c4a80a7',
  'inventories-import': 'https://www.notion.so/Gestion-des-inventaires-19fdfa80a296803fa9fac0d973ee7e2d?pvs=4#19fdfa80a296808a8b62c76eaab01640',
}
/* eslint-enable */
