<template>
  <v-btn
    icon
    small
    color="yellow"
    :href="target ?? '#'"
    target="_blank"
    :title="'Notion `' + topic + '`'"
  >
    <img
      v-if="target"
      src="@/assets/notion.svg"
      height="15"
    >
    <img
      v-else
      src="@/assets/notion-broken.svg"
      height="15"
    >
  </v-btn>
</template>

<script>
import notionTopics from '@/helpers/notion-topics'

export default {
  name: 'NotionHelp',
  props: {
    topic: {
      type: String,
      required: true,
    },
  },
  computed: {
    target () {
      return notionTopics[this.topic]
    },
  },
}
</script>
